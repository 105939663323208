import * as React from 'react';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Recorder } from '@features/milestones/types/api-types';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { PAGE_SIZE_OPTIONS, labels } from './constants';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';

enum ColumnKeys {
  Location = 'location',
  Name = 'name',
  Region = 'region',
  SiteCode = 'siteCode',
  TotalDevices = 'totalDevices',
}

export const getRecordersColumnDefinitions =
  (): TableProps.ColumnDefinition<Recorder>[] => [
    {
      id: ColumnKeys.Name,
      header: 'Recorder Name',
      cell: item => item.name,
      sortingField: ColumnKeys.Name,
    },
    {
      id: ColumnKeys.SiteCode,
      header: 'Site Code',
      cell: item => item.siteCode,
      sortingField: ColumnKeys.SiteCode,
    },
    {
      id: ColumnKeys.Region,
      header: 'Region',
      cell: item => item.region,
      sortingField: ColumnKeys.Region,
    },
    {
      id: ColumnKeys.Location,
      header: 'Location',
      cell: item => item.location,
      sortingField: ColumnKeys.Location,
    },
    {
      id: ColumnKeys.TotalDevices,
      header: 'Total Devices',
      cell: item => item.totalDevices,
      sortingField: ColumnKeys.TotalDevices,
    },
  ];

export const PageSizePreference: CollectionPreferencesProps.PageSizePreference =
  {
    title: labels.tablePreferences.titles.PAGE_SIZE_HEADER,
    options: PAGE_SIZE_OPTIONS,
  };

export const VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] =
  [
    {
      label: labels.tablePreferences.titles.VISIBLE_COLUMNS_SUBTITLE,
      options: getRecordersColumnDefinitions().map(
        (column: TableProps.ColumnDefinition<Recorder>) => {
          return {
            id: column.id ?? '',
            label: column.header?.toString() ?? '',
          };
        }
      ),
    },
  ];

export const DEFAULT_VISIBLE_COLUMNS = getRecordersColumnDefinitions().map(
  column => column.id ?? ''
);

export const RecordersFilteringProperties: PropertyFilterProps.FilteringProperty[] =
  [
    {
      key: ColumnKeys.Name,
      operators: ['=', '!=', ':', '!:'],
      propertyLabel: 'Recorder Name',
      groupValuesLabel: 'Recorder Name',
    },
    {
      key: ColumnKeys.SiteCode,
      operators: ['=', '!=', ':', '!:'],
      propertyLabel: 'Site Code',
      groupValuesLabel: 'Site Code',
    },
    {
      key: ColumnKeys.Region,
      operators: ['=', '!=', ':', '!:'],
      propertyLabel: 'Region',
      groupValuesLabel: 'Region',
    },
    {
      key: ColumnKeys.Location,
      operators: ['=', '!=', ':', '!:'],
      propertyLabel: 'Location',
      groupValuesLabel: 'Location',
    },
    {
      key: ColumnKeys.TotalDevices,
      operators: ['<', '<=', '>', '>=', '^'],
      propertyLabel: 'Total Devices',
      groupValuesLabel: 'Total Devices',
    },
  ];

export const RecordersFilteringOptions = [
  { propertyKey: ColumnKeys.Location, value: 'CORP' },
  { propertyKey: ColumnKeys.Location, value: 'Unfabric' },
];
