import * as React from 'react';
import { IconContext } from 'react-icons';
import Button from '@amzn/awsui-components-react/polaris/button';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';

import { Site } from '@features/milestones/types/api-types';
import ExpandIcon from '@features/milestones/tree/components/icons/ExpandIcon';
import { labels } from './constants';

enum ColumnKeys {
  Location = 'location',
  Name = 'name',
  RecorderCount = 'recorderCount',
  Region = 'region',
  TotalDevices = 'totalDevices',
  ViewTree = 'viewTree',
}

export const getSitesColumnDefinitions = (
  navigateToTreeBySite?: (siteCode: string) => void
): TableProps.ColumnDefinition<Site>[] => [
  {
    id: ColumnKeys.Name,
    header: 'Site Code',
    cell: item => item.name,
    sortingField: ColumnKeys.Name,
  },
  {
    id: ColumnKeys.ViewTree,
    header: 'View Tree',
    minWidth: 100,
    cell: item => {
      const isViewTreeDisabled = item.recorderCount === 0;
      return (
        <Button
          onClick={() => {
            navigateToTreeBySite?.(item.name);
          }}
          disabled={isViewTreeDisabled}>
          <IconContext.Provider key={'icp'} value={{ size: '0.8rem' }}>
            <ExpandIcon variant={isViewTreeDisabled ? 'disabled' : 'link'} />
          </IconContext.Provider>
        </Button>
      );
    },
  },
  {
    id: ColumnKeys.Region,
    header: 'Site Region',
    cell: item => item.region,
    sortingField: ColumnKeys.Region,
  },
  {
    id: ColumnKeys.RecorderCount,
    header: 'Number of Recorders',
    cell: item => item.recorderCount,
    sortingField: ColumnKeys.RecorderCount,
  },
  {
    id: ColumnKeys.TotalDevices,
    header: 'Total Devices',
    cell: item => item.totalDevices,
    sortingField: ColumnKeys.TotalDevices,
  },
];

export const VISIBLE_CONTENT_OPTIONS: CollectionPreferencesProps.VisibleContentOptionsGroup[] =
  [
    {
      label: labels.tablePreferences.titles.VISIBLE_COLUMNS_SUBTITLE,
      options: getSitesColumnDefinitions().map(
        (column: TableProps.ColumnDefinition<Site>) => {
          return {
            id: column.id ?? '',
            label: column.header?.toString() ?? '',
          };
        }
      ),
    },
  ];

export const DEFAULT_VISIBLE_COLUMNS = getSitesColumnDefinitions().map(
  column => column.id ?? ''
);

export const PAGE_SIZE_OPTIONS: CollectionPreferencesProps.PageSizeOption[] = [
  10, 20, 30, 50, 80,
].map(number => {
  return {
    value: number,
    label: labels.tablePreferences.pageSizeLabel(number),
  };
});

export const PageSizePreference: CollectionPreferencesProps.PageSizePreference =
  {
    title: labels.tablePreferences.titles.PAGE_SIZE_HEADER,
    options: PAGE_SIZE_OPTIONS,
  };
