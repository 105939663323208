/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from 'src/utils/helpers';
import { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { getDevicesForSites, getUserSites } from 'src/graphql/queries';
import { updateUserPrefs } from 'src/graphql/mutations';
import store, { RootState } from 'src/stores/slices/store';
import {
  setAllowedSites,
  setDevices,
} from 'src/stores/slices/isc/devicesSlice';
import { selectState } from 'src/features/isc/sagas/index';
import { DeviceFromDDV } from 'src/types';
import { setGlobalIsLoading } from 'src/stores/slices/userSlice';

export function* appSyncGetUserSites(action: any): Generator<any> {
  //yield delay(100);
  //log(`Getting Sites for ${action.payload.userId}`);
  // yield delay(500);
  const sites: any = yield API.graphql(
    graphqlOperation(getUserSites, { userId: action.payload.userId })
  );
  //log(sites.data.getUserSites);
  store.dispatch(setAllowedSites(sites.data.getUserSites));
}

export function* appsyncSetUserPrefs(action: any): Generator<any> {
  //yield delay(30000);
  //const resp = yield call([API, 'graphql'], graphqlOperation(updateUserPrefs, action.payload));
  const resp = yield API.graphql(
    graphqlOperation(updateUserPrefs, action.payload)
  );
  log('Appsync response', false, { resp: resp });
}

export function* appSyncGetDevicesForSites(action: any): Generator<any> {
  store.dispatch(setGlobalIsLoading(true));
  const selectedSites = yield selectState<string[]>(
    (state: RootState) => state.userPrefsState.selectedSites
  );
  log('Getting devices for ', false, selectedSites);
  try {
    const resp: any = yield API.graphql(
      graphqlOperation(getDevicesForSites, {
        sites: (selectedSites as string[]).join(','),
      })
    );
    log('Devices returned', false, resp);
    const devices = resp.data.getDevicesForSites as DeviceFromDDV[];
    devices.sort((a, b) => {
      if (a.device_name && b.device_name) {
        return a.device_name.localeCompare(b.device_name);
      }
      return 0;
    });
    store.dispatch(setDevices(devices));
  } catch (e) {
    log('Error getting devices', true, { error: e });
  }
  store.dispatch(setGlobalIsLoading(false));
}
