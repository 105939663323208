import { UINode } from '@features/milestones/types/ui-types';
import { log } from '@utils/helpers';

export interface MilestonesNodeInfo {
  location: string | undefined;
  name: string;
  region: string; // as per UINode...
  regionName?: string;
  type: string;
}

// Note: borrowed from BE src/domain/region/ogmaRegion.ts
// Also: UINode defines 'region' as a string, not a number (!)
export const enum RegionId {
  AMER = '2',
  EMEA = '3',
  // eslint-disable-next-line typescript-sort-keys/string-enum
  APAC = '4',
}

export const enum RegionName {
  APsouth1 = 'ap-south-1',
  EUwest1 = 'eu-west-1',
  USeast1 = 'us-east-1',
  USwest2 = 'us-west-2',
}

// Note: borrowed from BE src/models/locationModel.ts
export const enum Location {
  corp = 'corp',
  unfabric = 'unfabric',
}

// Note: borrowed from BE src/domain/region/milestoneRegion.ts
// Also: UINode defines 'region' as a string, not a number (!)
export function getRegionName(regionId: string, location?: string): string {
  switch (regionId) {
    case RegionId.AMER:
      return RegionName.USeast1;
    case RegionId.EMEA:
      return RegionName.EUwest1;
    case RegionId.APAC:
      return !location || location === Location.corp
        ? RegionName.USwest2
        : RegionName.APsouth1;
    default:
      throw new Error(`Unknown regionId '${regionId}'`);
  }
}

export function getRegionId(regionName: string): string {
  switch (regionName) {
    case RegionName.USeast1:
      return RegionId.AMER;
    case RegionName.EUwest1:
      return RegionId.EMEA;
    case RegionName.USwest2:
    case RegionName.APsouth1:
      return RegionId.APAC;
    default:
      throw new Error(`Unknown regionName '${regionName}'`);
  }
}

// Note: borrowed from BE src/domain/milestone/decodeTreeId.ts
//       (which uses zod - if FE adopts zod, should update this)
export function decodeTreeId(nodeId: string): MilestonesNodeInfo {
  try {
    const nodeInfo: MilestonesNodeInfo = JSON.parse(atob(nodeId));
    nodeInfo.location = nodeInfo.location?.toLowerCase();
    nodeInfo.region = nodeInfo.region.toString();
    nodeInfo.regionName = getRegionName(nodeInfo.region, nodeInfo.location);
    return nodeInfo;
  } catch (e) {
    log(`Error parsing tree id ${nodeId}; `, true, e);
    throw new Error('failed to parse tree id');
  }
}

// Note: borrowed from BE src/domain/milestone/encodeTreeId.ts
//       (which uses zod - if FE adopts zod, should update this)
export function encodeTreeId(nodeInfo: MilestonesNodeInfo): string; // overload
export function encodeTreeId(nodeInfo: UINode): string; // overload

export function encodeTreeId(nodeInfo: MilestonesNodeInfo | UINode): string {
  try {
    const region =
      'regionName' in nodeInfo
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          Number(getRegionId(nodeInfo.regionName!))
        : Number(nodeInfo.region);
    return btoa(
      JSON.stringify({
        type: nodeInfo.type,
        location: nodeInfo.location,
        name: nodeInfo.name,
        region,
      })
    );
  } catch (e) {
    log('Error encoding tree id from nodeInfo ', true, nodeInfo);
    throw new Error('failed to parse node info');
  }
}

export function isEqualNodesArrays(
  nodes1: Array<UINode>,
  nodes2: Array<UINode>
): boolean {
  return (
    nodes1.length === nodes2.length &&
    nodes1
      .map(node => node.name)
      .sort()
      .join(',') ===
      nodes2
        .map(node => node.name)
        .sort()
        .join(',')
  );
}
